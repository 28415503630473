<template>
   <ObiText class="obi-tabs" v-bind="attrs" v-on="$listeners">
      <ObiText as="ul" class="nav">
         <ObiText as="li" class="nav-item" v-for="item in items" :key="item.id">
            <ObiText
               as="a"
               class="nav-link"
               v-html="item.label"
               @click="setTab(item)"
               :class="[activeTab === item.id && 'active']"
            />
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiTabs',
   props: {
      value: {},
      items: {
         type: Array,
         default: () => [],
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      activeTab: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
            this.$emit('tabChanged', val);
         },
      },
   },
   methods: {
      setTab(item) {
         this.activeTab = item.id;
      },
   },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-tabs {
   .nav {
      .nav-item {
         .nav-link {
            color: #b5b5b5;
            cursor: pointer;
            padding: 1rem 1.5rem;
            border-radius: 6px 6px 0 0;
            border-bottom: 2px solid transparent;

            &:hover {
               color: $primary;
            }

            &.active {
               color: $primary;
               background-color: #e5efff;
               border-bottom-color: $primary;
            }
         }
      }
   }
}
</style>
